import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/navBar';
import Home from './components/home';
import About from './components/about';
import Merchandise from './components/merchandise';
import Contact from './components/contact';
import CornerstoneFestival from './components/CornerstoneFestival';
import CornerstoneReview from './components/CornerstoneReview';
import Gallery2 from './components/gallery2';
import './App.css';

function App() {
    return (
        <Router>
            <div className="App">
                <NavBar />
                <header className="App-header">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/merchandise" element={<Merchandise />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/cornerstone" element={<CornerstoneFestival />} />
                        <Route path="/cornerstone-review" element={<CornerstoneReview />} />
                        <Route path="/gallery" element={<Gallery2 />} />
                    </Routes>
                </header>
            </div>
        </Router>
    );
}

export default App;