import React, { useEffect, useState } from 'react';
import './gallery2.css';

const Gallery2 = () => {
    const [gigImages, setGigImages] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        const importAll = (r) => {
            return r.keys().map((fileName) => {
                const fullPath = fileName.replace(/^\.\//, '');
                const parts = fullPath.split('/'); // Extract gig name from path
                const gigName = parts.length > 1 ? parts[0] : 'Unknown'; // Default to 'Unknown' if no folder
                
                return {
                    src: r(fileName),
                    alt: fullPath.replace(/\.(png|jpe?g|svg)$/i, ''),
                    gig: gigName
                };
            });
        };

        try {
            // Import all images from the `photos` directory (including subdirectories)
            const context = require.context('../photos', true, /\.(png|jpe?g|svg)$/i);
            const importedImages = importAll(context);

            // Group images by gig
            const groupedImages = importedImages.reduce((acc, image) => {
                if (!acc[image.gig]) acc[image.gig] = [];
                acc[image.gig].push(image);
                return acc;
            }, {});

            setGigImages(groupedImages);
        } catch (error) {
            console.error('Error importing images:', error);
        }
    }, []);

    return (
        <div className="gallery-container">
            <h1>Gallery</h1>
            {Object.keys(gigImages).length > 0 ? (
                Object.keys(gigImages).map((gig, index) => (
                    <div key={index} className="gig-section">
                        <h2>{gig.replace(/-/g, ' ').toUpperCase()}</h2>
                        <div className="image-grid">
                            {gigImages[gig].map((image, imgIndex) => (
                                <img 
                                    key={imgIndex}
                                    src={image.src} 
                                    alt={image.alt}
                                    className="grid-image"
                                    loading="lazy"
                                    onClick={() => setSelectedImage(image.src)}
                                    onError={(e) => {
                                        console.error(`Error loading image ${image.alt}:`, e);
                                        e.target.src = 'https://via.placeholder.com/400x300?text=Image+Not+Found';
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                ))
            ) : (
                <p>No images found in the gallery.</p>
            )}

            {/* Fullscreen Image Modal */}
            {selectedImage && (
                <div className="image-modal" onClick={() => setSelectedImage(null)}>
                    <span className="close-btn">&times;</span>
                    <img src={selectedImage} alt="Full View" className="modal-image" />
                </div>
            )}
        </div>
    );
};

export default Gallery2;
