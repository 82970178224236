import React from 'react';
import './home.css';
import bandImage from './bandImage.jpg'; 
import { FaCalendarAlt, FaMapMarkerAlt, FaPlayCircle } from 'react-icons/fa'; // Icons

const events = [
    { date: "Friday 21st, 2025", city: "Belfast", venue: "Voodoo" },
    { date: "March 15th, 2025", city: "Bangor", venue: "Imperial Bar" },
    { date: "March 16th, 2025", city: "Newcastle", venue: "Tap Room" },
    { date: "April 20th, 2025", city: "Newcastle", venue: "Tap Room" },
    { date: "May 3rd, 2025", city: "Private Function", venue: "Secret Location" },
    { date: "June 7th, 2025", city: "Downpatrick", venue: "Ballydugan Mill" },
    { date: "June 14th, 2025", city: "Belfast", venue: "Black Box" },
    { date: "August 2nd, 2025", city: "Ahoghil", venue: "Diamond Rock Club" },
    { date: "August 9th, 2025", city: "Annacloy Sunflower Cottage", venue: "CornerstoneFestival" },
    { date: "September 6th, 2025", city: "Cookstown", venue: "Railway" }
];

const pastEvents = [
    { date: "January 24th, 2025", city: "Belfast", venue: "Empire", link: "https://youtu.be/TV09xZ2llVM?si=JIYmHDgBu-jJOS49" }
];

const Home = () => {
    return (
        <div className="home-container">
            <div className="hero-section">
                <img src={bandImage} alt="Black Dog Moon" className="hero-image" />
                <div className="hero-text">
                    <h1>Black Dog Moon</h1>
                    <p>Irish rockers Black Dog Moon just released their debut album <b>Black Dog Moon</b> on June 28, 2024, to critical acclaim. The Dogs are ready, are you? <i>Unleash the dogs.</i></p>
                </div>
            </div>

            <div className="content-section">
                <h2>🎵 Latest News</h2>
                <p>Stay tuned for the latest updates on our music, tours, and more. </p>
            </div>

            <div className="content-section">
                <h2>📅 Upcoming Events</h2>
                <div className="event-grid">
                    {events.map((event, index) => (
                        <div key={index} className="event-card">
                            <FaCalendarAlt className="event-icon" />
                            <div className="event-details">
                                <h3>{event.date}</h3>
                                <p><FaMapMarkerAlt /> {event.city} - {event.venue}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <p className="more-events">Stay posted for more gigs into 2025 and beyond!</p>
            </div>

            <div className="content-section">
                <h2>🎬 Past Events</h2>
                <div className="event-grid">
                    {pastEvents.map((event, index) => (
                        <a key={index} href={event.link} target="_blank" rel="noopener noreferrer" className="event-card past-event">
                            <FaPlayCircle className="event-icon" />
                            <div className="event-details">
                                <h3>{event.date}</h3>
                                <p><FaMapMarkerAlt /> {event.city} - {event.venue}</p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Home;
